import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AuthUser from "../components/AuthUser";
import FloorTopbar from "../components/FloorTopbar";
import { useUserContext } from "../context/user_context";
import { getLiveWeatherData } from "../utils/Api";
import { intervalTime } from "../utils/constants";
import Curing from "../components/RahimAfrooz/Curing";
import ETP from "../components/RahimAfrooz/ETP";
import PlateDry from "../components/RahimAfrooz/PlateDry";
import PasteMixing from "../components/RahimAfrooz/PasteMixing";
import Substation from "../components/RahimAfrooz/Substation";
import moment from "moment";
import CuringTableView from "../components/RahimAfrooz/CuringTableView";

const LivePage = () => {
  const [summary, setSummary] = useState([]);
  const [curingData, setCuringData] = useState([]);
  const [etpData, setEtpData] = useState([]);
  const [pdoData, setPdoData] = useState([]);
  const [pasteMisxingData, setPasteMixingData] = useState([]);
  const [energyData, setEnergyData] = useState([]);
  const { selectedFactoryId } = useUserContext();
  const { user, http } = AuthUser();
  const [selectedFloorId, setSelectedFloorId] = useState(0);
  const [selectedCompatment, setSelectedCompartment] = useState("");
  const [dataUpdateInfo, setDataUpdateInfo] = useState(null);
  const [curingDataView, setCuringDataView] = useState("tile");

  const navigate = useNavigate();

  const fetchTotalMachineSummary = async () => {
    getLiveWeatherData(selectedFloorId)
      .then((data) => {
        setDataUpdateInfo({
          factory: data.factory,
          server_time: data.server_time,
          last_ping:data.last_ping
        });
        const categorizedData = data.data.reduce(
          (accumulator, entry) => {
            const sensorType = entry.sensor.sensor;
            switch (sensorType) {
              case "curing":
                accumulator.curing.push(entry);
                break;
              case "etp":
                accumulator.etp.push(entry);
                break;
              case "pdo":
                accumulator.pdo.push(entry);
                break;
              case "pastemixing":
                accumulator.pastemixing.push(entry);
                break;
              case "energy":
                accumulator.energy.push(entry);
                break;
              default:
                break;
            }
            return accumulator;
          },
          { curing: [], etp: [], pdo: [], pastemixing: [], energy: [] }
        );

        setCuringData(categorizedData.curing);
        setEtpData(categorizedData.etp);
        setPdoData(categorizedData.pdo);
        setPasteMixingData(categorizedData.pastemixing);
        setEnergyData(categorizedData.energy);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchTotalMachineSummary();
    const interval = setInterval(() => {
      fetchTotalMachineSummary();
    }, intervalTime);

    return () => clearInterval(interval);
  }, [selectedFactoryId, selectedFloorId]);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    return () => {};
  }, []);

  const sensors = JSON.parse(sessionStorage.getItem("sensors"));

  return (
    <Wrapper>
      <main className="main page-100">
        <section className="section-center">
          <div className="d-flex justify-content-between">
            <h3 className="pe-2">Live</h3>
            <div className="text-end">{`Live data update on ${moment(
              dataUpdateInfo?.last_ping
            ).format("MMMM DD, YYYY - hh:mm a") || moment(
              dataUpdateInfo?.server_time
            ).format("MMMM DD, YYYY - hh:mm a")} from ${
              dataUpdateInfo?.factory
            }`}</div>
          </div>
          <hr />

          <div className="my-4">
            <FloorTopbar
              selectedFloorId={selectedFloorId}
              setSelectedFloorId={setSelectedFloorId}
              selectedCompatment={selectedCompatment}
              setSelectedCompartment={setSelectedCompartment}
            />
          </div>

          <hr />

          <div className="row">
            {curingData?.length > 0 && (
              <>
                <div className="curing-section">
                  <h3>Curing Section</h3>
                  <div className="view-switcher">
                    <button
                      className={`btn ${
                        curingDataView === "tile" ? "active" : ""
                      }`}
                      onClick={() => setCuringDataView("tile")}
                    >
                      Tile View
                    </button>
                    <button
                      className={`btn ${
                        curingDataView === "table" ? "active" : ""
                      }`}
                      onClick={() => setCuringDataView("table")}
                    >
                      Table View
                    </button>
                  </div>
                </div>
                {curingDataView === "tile" ? (
                  curingData?.map((cd, i) => {
                    return <Curing key={i} {...cd} />;
                  })
                ) : (
                  <CuringTableView curingData={curingData} />
                )}
              </>
            )}
          </div>

          <div className="mt-4 mb-4">
            <div className="row">
              {etpData?.length > 0 && <h3>Effluent Treatment Plant (ETP)</h3>}
              {etpData?.map((etp, i) => {
                return <ETP key={i} {...etp} />;
              })}
            </div>
          </div>

          <div className="mt-4 mb-4">
            <div className="row">
              {pdoData?.length > 0 && <h3>Plate Drying Section</h3>}
              {pdoData?.map((pdo, i) => {
                return <PlateDry key={i} {...pdo} />;
              })}
            </div>
          </div>

          <div className="mt-4 mb-4">
            <div className="row">
              {pasteMisxingData?.length > 0 && <h3>Paste Mixing</h3>}
              {pasteMisxingData?.map((pm, i) => {
                return <PasteMixing key={i} {...pm} />;
              })}
            </div>
          </div>

          <div className="mt-4 mb-4">
            <div className="row">
              {energyData?.length > 0 && <h3>Substation</h3>}
              {energyData?.map((st, i) => {
                return <Substation key={i} {...st} />;
              })}
            </div>
          </div>
        </section>
      </main>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  font-family: var(--ff-primary);


  .btn {
    background-color: #ffffff;
    border: 1px solid #D6DADE;
    width: 91px;
    height: 30px;
    position: relative;
    border-radius: 32px;
    color: #D6DADE;
    text-align: center;
    cursor: pointer;
    transition: all 0.1s ease;
    font-size: 10px;
    white-space:nowrap;
  }

  .btn:hover {
    background-color: #4040F2;
    transform: translateY(-0.5px);
    color: white;
  }

  .btn.active {
    background-color: #4040F2;
    border-color: #4040F2;
    color: white;
  }

  .view-switcher {
    display: flex;
    gap: 1rem;
  }

  h3 {
    color: #333;
    margin: 8px 0;
  }

  .curing-section {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1rem;
  }
  .table-view {
    background-color: #f0f8ff;
    color: #333;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.1s ease;
  }

  .table-view:hover {
    background-color: #dbe9f4;
  }

  .table-view table {
    width: 100%;
    border-collapse: collapse;
  }

  .table-view th,
  .table-view td {
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
  }

  .table-view th {
    background-color: #87ceeb;
    color: white;
  }

  .table-view tbody tr:hover {
    background-color: #dbe9f4;
  }

  .tile-view {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
  }

  .tile-view div {
    background-color: #f0f8ff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.1s ease;
  }

  .tile-view div:hover {
    background-color: #dbe9f4;
  }

  .tile-view h3 {
    color: #333;
    font-size: 1.2rem;
  }
`;

export default LivePage;
