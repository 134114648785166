import React,{useState} from "react";
import styled from "styled-components";

const ViewSortingButton = ({ graph_view, changeView,graphTimeInterval,setGraphimeInterval }) => {
    const [range,setRange] = useState(5);
  return (
    <Wrapper className="ms-auto">
      <div>
        <button
          className={`btn ${graph_view ? "active" : null}`}
          id="graph_view"
          onClick={changeView}
        >
          graph view
        </button>
        <button
          className={`btn ${!graph_view ? "active" : null}`}
          id="table_view"
          onClick={changeView}
        >
          table view
        </button>
      </div>
      {/* {graph_view && <div><input type="range" value={graphTimeInterval} onChange={e=>setGraphimeInterval(e.target.value)} id="points" name="points" min="5" max="1000" className='range'/></div>} */}
    </Wrapper>
  );
};
const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  .btn {
    margin: 0 2px;
    background: #8c9ab4;
    margin-bottom: 10px;
  }
  .active {
    background: var(--clr-primary-1);
    color: var(--clr-primary-10);
  }
  .range{
 
  }
`;
export default ViewSortingButton;
