import React from "react";

const Substation = ({ sensor_name, sensor_data, color }) => {
  const {
    totalConsumption,
    activePower,
    reactivePower,
    statusCode,
    powerFactor,
    voltage1,
    voltage2,
    voltage3,
    current1,
    current2,
    current3,
    demand
  } = sensor_data;

  return (
    <div className="col-md-6 mb-4">
      <div
        className="card bg-white ps-4 pt-3 pb-3 shadow"
        style={{ borderRadius: "5px" }}
      >
        <div className="d-flex justify-content-start ">
          <div
            style={{
              height: "15px",
              width: "15px",
              backgroundColor:"green",
              borderRadius: "50%",
              marginTop: "1%",
            }}
          ></div>
          <p className="ms-2 fw-bold text-info text-uppercase">{sensor_name}</p>
        </div>

        <div className="d-flex justify-content-between p-2">
          <div className="d-grid">
            <span className="fw-bold fs-6 mt-2">Total Consumption</span>
            <span className="fs-4 mt-2 text-muted fw-bold">
              {parseFloat(totalConsumption)?.toFixed(2)} kWh
            </span>
            <span className="fw-bold fs-6 mt-2">Active Power</span>
            <span className="fs-4 mt-2 text-muted fw-bold">
              {parseFloat(activePower)?.toFixed(2)} kW
            </span>
            <span className="fw-bold fs-6 mt-2">Reactive Power</span>
            <span className="fs-4 mt-2 text-muted fw-bold">
              {parseFloat(reactivePower)?.toFixed(2)} kVar
            </span>

            <span className="fw-bold fs-6 mt-2">Power Factor</span>
            <span className="fs-4 mt-2 text-muted fw-bold">{parseFloat(powerFactor)?.toFixed(2)}</span>
          </div>

          <div className="d-grid">
            <span className="fw-bold fs-6 mt-2">Line 1 Voltage</span>
            <span className="fs-4  text-muted fw-bold">{parseFloat(voltage1)?.toFixed(2)} V</span>
            <span className="fw-bold fs-6 mt-2">Line 2 Voltage</span>
            <span className="fs-4  text-muted fw-bold">{parseFloat(voltage2)?.toFixed(2)} V</span>
            <span className="fw-bold fs-6 mt-2">Line 3 Voltage</span>
            <span className="fs-4  text-muted fw-bold">{parseFloat(voltage3)?.toFixed(2)} V</span>
          </div>
          <div className="d-grid">
            <span className="fw-bold fs-6 mt-2">Line 1 Current</span>
            <span className="fs-4  text-muted fw-bold">{parseFloat(current1)?.toFixed(2)} A</span>
            <span className="fw-bold fs-6 mt-2">Line 2 Current</span>
            <span className="fs-4  text-muted fw-bold">{parseFloat(current2)?.toFixed(2)} A</span>
            <span className="fw-bold fs-6 mt-2">Line 3 Current</span>
            <span className="fs-4  text-muted fw-bold">{parseFloat(current3)?.toFixed(2)} A</span>
            <span className="fw-bold fs-6 mt-2">Demand</span>
            <span className="fs-4  text-muted fw-bold">{parseFloat(demand)?.toFixed(2)} kW</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Substation;
