import { Skeleton } from "@material-ui/lab";
import React, { useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import styled from "styled-components";
import moment from "moment";

const EnergyData = ({
  reportData,
  loading,
  graphView,
  totalConsumption,
  maxDemand,
}) => {
  const tableRef = useRef(null);
  return (
    <section className="section">
      <div className="section-center">
        <Wrapper
          className={
            graphView ||
            loading ||
            reportData?.length === 0 ||
            reportData?.length === undefined
              ? "d-none"
              : ""
          }
        >
          <div className="summary">
            <h4>Total Consumption : {totalConsumption} kwh</h4>
            <h4>Maximum Demand : {maxDemand} kwh</h4>
          </div>
          <div className="b-table">
            <div>
              <table className="table bg-white rounded" ref={tableRef}>
                <thead
                  style={{ borderBottom: "1px solid #FAFAFA" }}
                  className="text-muted"
                >
                  <tr
                    style={{
                      height: "50px",
                      margin: "auto",
                      verticalAlign: "middle",
                      borderBottom: "1px solid #FAFAFA",
                      borderStyle: "none",
                    }}
                  >
                    <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                      <div className="text-center">Date</div>
                    </th>
                    <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                      <div className="text-center">Time</div>
                    </th>
                    <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                      <div className="text-center">Consumption</div>
                    </th>
                    <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                      <div className="text-center">Active Power</div>
                    </th>
                    <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                      <div className="text-center">Reactive Power</div>
                    </th>
                    <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                      <div className="text-center">Voltage 1</div>
                    </th>
                    <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                      <div className="text-center">Voltage 2</div>
                    </th>
                    <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                      <div className="text-center">Voltage 3</div>
                    </th>
                    <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                      <div className="text-center">Current 1</div>
                    </th>
                    <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                      <div className="text-center">Current 2</div>
                    </th>
                    <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                      <div className="text-center">Current 3</div>
                    </th>
                    <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                      <div className="text-center">P.F.</div>
                    </th>
                    <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                      <div className="text-center">Demand</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reportData?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td className="text-center">
                          {" "}
                          {moment(item["acquisition-time"]).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>
                          <div className="mt-2 text-center">
                            <span className="rounded-pill p-2">
                              {moment(item["acquisition-time"]).format(
                                "hh:mm a"
                              )}
                            </span>
                          </div>
                        </td>
                        <td className="text-center">
                          {" "}
                          {item["totalConsumption"]?.toFixed(2)} KWh
                        </td>
                        <td className="text-center">
                          {" "}
                          {item["activePower"]?.toFixed(2)} KW
                        </td>
                        <td className="text-center">
                          {" "}
                          {item["reactivePower"]?.toFixed(2)} KVar
                        </td>
                        <td className="text-center">
                          {" "}
                          {item["voltage1"]?.toFixed(2)} V
                        </td>
                        <td className="text-center">
                          {" "}
                          {item["voltage2"]?.toFixed(2)} V
                        </td>
                        <td className="text-center">
                          {" "}
                          {item["voltage3"]?.toFixed(2)} V
                        </td>
                        <td className="text-center">
                          {" "}
                          {item["current1"]?.toFixed(2)} A
                        </td>
                        <td className="text-center">
                          {" "}
                          {item["current2"]?.toFixed(2)} A
                        </td>
                        <td className="text-center">
                          {" "}
                          {item["current3"]?.toFixed(2)} A
                        </td>
                        <td className="text-center">
                          {" "}
                          {item["powerFactor"]?.toFixed(2)}
                        </td>
                        <td className="text-center">
                          {" "}
                          {item["demand"]?.toFixed(2)} Kw
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Wrapper>
      </div>
    </section>
  );
};
const Wrapper = styled.section`
  .summary {
    background-color: #e3f2fd;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    color: #0d47a1;
  }
  .b-table {
    width: 100%;
    border-collapse: collapse;
    border: 3px solid var(--clr-primary-1);
    border-radius: 8px !important;
    height: 500px;
    overflow-y: auto;

    thead th {
      position: sticky;
      top: 0;
      background-color: #f1f5f8;
    }
  }
`;
export default EnergyData;
