import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import styled from "styled-components";
import SortView from "../components/SortView";
import ViewSortingButton from "../components/ViewSortingButton";
import { URL, intervalTime } from "../utils/constants";
import usePrevious from "../hooks/usePrevious";
import Motion from "../components/Motion";
import { useUserContext } from "../context/user_context";
import AuthUser from "../components/AuthUser";
import { axios_export_table_instance } from "../utils/axios";
import { Hero } from "../components/Hero";
import { toast } from "react-toastify";
import { TbTemperatureCelsius, TbTemperatureFahrenheit } from "react-icons/tb";
import { DownloadTableExcel } from "react-export-table-to-excel";
import TableData from "../components/TableData";
import { Skeleton } from "@material-ui/lab";

const ReportPage = ({ state, handleCheck, cTf }) => {
  const currentDate = moment().format("YYYY-MM-DDTHH:mm");
  const prevDate = moment().subtract(1, "days").format("YYYY-MM-DDTHH:mm");
  const maxDate = moment().endOf("day").format("YYYY-MM-DDTHH:mm");
  const [graphView, setGraphView] = useState(true);
  const { user, http } = AuthUser();
  const { selectedFactoryId } = useUserContext();
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [warehouseList, setWarehouseList] = useState([]);
  const [warehouseId, setWarehouseId] = useState(null);
  const [reportType, setReportType] = useState("");
  const [report, setReport] = useState([]);
  const [tabularReport, setTabularReport] = useState([]);
  const [startDate, setStartDate] = useState(prevDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [deviceId, setDeviceId] = useState(null);
  const [batches, setBatches] = useState([]);
  const [batchNo, setBatchNo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingOnExport, setLoadingOnExport] = useState(false);
  const [loadingOnDownload, setLoadingOnDownload] = useState(false);
  const [loadingWareHouse, setLoadingWareHouse] = useState(false);
  const [loadingBatch, setLoadingBatch] = useState(false);
  const [thresoldValues, setThresoldValues] = useState({});
  const table_ref = useRef(null);
  const [graphTimeInterval, setGraphimeInterval] = useState(5);
  const [totalConsumption,setTotalConsumption] = useState(0);
  const [maxDemand,setMaxDemand] = useState(0);

  const changeView = (e) => {
    const viewType = e.target.id;
    if (viewType === "table_view") {
      setGraphView(false);
    }
    if (viewType === "graph_view") {
      setGraphView(true);
    }
  };

  //fetch process
  const fetchLocations = async () => {
    try {
      const { data } = await http.get(
        `/pms/v1/processes?factory_id=${user.default_factory}`
      );
      setLocations(data);
    } catch (error) {
      console.log(`Error in fetching Process ${error}`);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, [selectedFactoryId]);

  const handleOnLocationChange = (e) => {
    setSelectedLocationId(parseInt(e.target.value));
    setSelectedLocation(e.target.options[e.target.selectedIndex].text);
    // setWarehouseId(!warehouseId);
  };

  const handleOnWarehouseChange = (e) => {
    setWarehouseId(parseInt(e.target.value));
    setWarehouseId(e.target.value);
    setReportType(e.target[e.target.selectedIndex].getAttribute("type"));
  };

  const handleOnStartDateChange = (e) => {
    setStartDate(e.target.value);
    // setStartDate(e.target.value);
  };

  const handleOnEndDateChange = (e) => {
    // setEndDate(e.target.value.split('T').join(' '));
    setEndDate(e.target.value);
  };

  //get unit information (id,identifier)
  const getAllWarehouse = async () => {
    setLoadingWareHouse(true);
    const { data } =
      selectedLocationId &&
      (await http.get(`/pms/v1/sensors?process_id=${selectedLocationId}`));
    setWarehouseList(data);
    // data && data.length > 0 ? setWarehouseList(data) : setWarehouseList([]);
    setLoadingWareHouse(false);

    //set default deviceId
    // data && data.length > 0
    //   ? setDeviceId(data[0].identifier)
    //   : setDeviceId(null);
    // data && data.length > 0
    //   ? setWarehouseId(data[0].warehouseId)
    //   : setWarehouseId(null);
  };

  useEffect(() => {
    getAllWarehouse();
  }, [selectedLocationId]);

  //fetch batches for paste mixing chmaber
  useEffect(async () => {
    if (selectedLocationId === 64) {
      try {
        setLoadingBatch(true);
        const res = await http.get(`${URL}/pms/v1/batch_no`);
        if (res.data) {
          setBatches(res.data);
          setLoadingBatch(false);
        } else {
          setBatches([]);
          setLoadingBatch(false);
        }
      } catch (error) {
        setBatches([]);
        console.log(`Error in fetching batches ${error}`);
        setLoadingBatch(false);
      }
    }
  }, [selectedLocationId]);

  const fetchWeatherReport = async () => {
    try {
      setLoading(true);
  
      // Ensure that startDate and endDate retain both date and time information.
      const formattedStartDate = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
      const formattedEndDate = moment(endDate).format("YYYY-MM-DD HH:mm:ss");
  
      const res =
        warehouseId &&
        reportType &&
        startDate &&
        endDate &&
        (await http.get(
          `${URL}/pms/v1/report/${reportType}?deviceId=${warehouseId}${
            selectedLocationId === 64 && batchNo ? `&batch_no=${batchNo}` : ""
          }&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
        ));
  
      if (res) {
        setReport(res.data);
        setTabularReport(res.data);
  
        if (warehouseId == 355) {
          const totalConsumption = res.data.reduce((acc, item) => {
            const consumption = item.totalConsumption;
            return acc + (consumption != null ? consumption : 0); // Use 0 if consumption is null or undefined
          }, 0);
            
          const maxDemand = Math.max(
            ...res.data.map(item => item.demand).filter(demand => demand != null)
          );
  
          setTotalConsumption(totalConsumption);
          setMaxDemand(maxDemand);
        }
  
        setLoading(false);
      } else {
        setReport([]);
        setTabularReport([]);
        setLoading(false);
      }
    } catch (error) {
      setReport([]);
      setTabularReport([]);
      setLoading(false);
      toast.dismiss();
      toast.error(error.response.data[0]);
    }
  };
  

  useEffect(() => {
    fetchWeatherReport();
  }, [startDate, endDate, warehouseId, reportType, batchNo]);


  return (
    <Motion>
      <Wrapper className="main page-100">
        <div className="section">
          <div className="section-center">
            <div className="row">
              <div className="col-12 col-lg-3">
                <div className="box">
                  <div className="row">
                    <label htmlFor="" className="col-6 my-1 col-lg-4 fw-bold">
                      {" "}
                      Process
                    </label>
                    <div className="col-6 col-lg-8">
                      <select
                        disabled={loading}
                        name="location"
                        className="select-input rounded-pill align-middle"
                        id="locations"
                        onChange={(e) => handleOnLocationChange(e)}
                      >
                        <option value="">Select Process</option>
                        {locations.map((location) => (
                          <option
                            key={location.process_id}
                            value={location.process_id}
                          >
                            {location.process_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-3">
                <div className="box">
                  <div className="row">
                    <label htmlFor="" className="col-6 my-1 col-lg-4 fw-bold">
                      {" "}
                      Unit
                    </label>
                    <div className="col-6 col-lg-8">
                      <select
                        disabled={loading}
                        name="warehouse"
                        className="select-input rounded-pill"
                        id="locations"
                        onChange={(e) => handleOnWarehouseChange(e)}
                      >
                        <option value="">Select Unit</option>
                        {warehouseList?.map((unit) => (
                          <option
                            key={unit.deviceId}
                            value={unit.deviceId}
                            type={unit.sensor_type}
                          >
                            {unit.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {selectedLocationId === 64 && (
                <div className="col-12 col-lg-3">
                  <div className="box">
                    <div className="row">
                      <label htmlFor="" className="col-6 my-1 col-lg-4 fw-bold">
                        {" "}
                        Batch No
                      </label>
                      <div className="col-6 col-lg-8">
                        <select
                          disabled={loadingBatch}
                          name="batch"
                          className="select-input rounded-pill"
                          id="locations"
                          onChange={(e) => setBatchNo(e.target.value)}
                        >
                          <option value="">Batch</option>
                          {batches?.map((batch) => (
                            <option key={batch.batchNo} value={batch.batchNo}>
                              {batch.batchNo}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-12 col-lg-3">
                <div className="box">
                  <div className="row">
                    <label htmlFor="" className="col-6 my-1 col-lg-4  fw-bold">
                      From :{" "}
                    </label>
                    <div className="col-6 col-lg-8">
                      <input
                        // disabled={loading}
                        type="datetime-local"
                        // type="datetime-local"
                        value={startDate}
                        onChange={(e) => handleOnStartDateChange(e)}
                        className="select-input  rounded-pill align-middle"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-3">
                <div className="box">
                  <div className="row">
                    <label htmlFor="" className="col-6 my-1 col-lg-4  fw-bold">
                      To :{" "}
                    </label>
                    <div className="col-6 col-lg-8">
                      <input
                        // disabled={loading}
                        type="datetime-local"
                        value={endDate}
                        onChange={(e) => handleOnEndDateChange(e)}
                        className="select-input rounded-pill align-middle"
                        max={maxDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="text-center">
                <ViewSortingButton
                  changeView={changeView}
                  graph_view={graphView}
                  graphTimeInterval={graphTimeInterval}
                  setGraphimeInterval={setGraphimeInterval}
                />
              </div>

              {loading ? (
                <Skeleton variant="rect" width="100%" height={500} />
              ) : (
                <SortView
                  loading={loading}
                  report={report}
                  reportType={reportType}
                  table_report={tabularReport}
                  thresoldValues={thresoldValues}
                  changeView={changeView}
                  graph_view={graphView}
                  state={state}
                  handleCheck={handleCheck}
                  cTf={cTf}
                 
                />
              )}
            </div>
          </div>

          <TableData
            loading={loading}
            reportType={reportType}
            report={report}
            graphView={graphView}
            totalConsumption={totalConsumption}
            maxDemand={maxDemand}
          />
        </div>
      </Wrapper>
    </Motion>
  );
};

const Wrapper = styled.section`
  .box {
    text-align: start;
    padding: 5px 0;
    margin: 5px 0;
    label {
      white-space: nowrap;
      font-weight: 500;
      font-family: var(--ff-primary);
    }

    #locations,
    input {
      background: transparent;
      padding: 5px;
      width: 150px;
      height: 30px;
      /* border: none; */
      font-size: 14px;
      /* box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2); */
      /* -webkit-appearance: button; */
      /* appearance: button; */
      border: 1px solid var(--clr-primary-1);
      outline: none;
      cursor: pointer;
      text-transform: capitalize;
      font-weight: normal;
    }
  }

  .box select option {
    padding: 30px;
    text-align: center;
    cursor: pointer;
    text-transform: capitalize;
  }
`;
export default ReportPage;
