import React from "react";
import styled from "styled-components";

const CuringTableView = ({ curingData }) => {
  return (
    <Wrapper>
      <div className="b-table">
        <table className="table bg-white rounded">
          <thead className="text-muted">
            <tr style={{ height: "50px", verticalAlign: "middle" }}>
              <th
                scope="col"
                className="text-center"
                style={{ color: "#4F5B67" }}
              >
                Chamber Name
              </th>
              <th
                scope="col"
                className="text-center"
                style={{ color: "#4F5B67" }}
              >
                Running Time
              </th>
              <th
                scope="col"
                className="text-center"
                style={{ color: "#4F5B67" }}
              >
                Relative Humidity
              </th>
              <th
                scope="col"
                className="text-center"
                style={{ color: "#4F5B67" }}
              >
                Temperature
              </th>
              <th
                scope="col"
                className="text-center"
                style={{ color: "#4F5B67" }}
              >
                Status
              </th>

              <th
                scope="col"
                className="text-center"
                style={{ color: "#4F5B67" }}
              >
                Stage No
              </th>
              <th
                scope="col"
                className="text-center"
                style={{ color: "#4F5B67" }}
              >
                Program No
              </th>
            </tr>
          </thead>
          <tbody>
            {curingData?.map((sensorData, index) => {
              const {
                presentTemperature,
                setTemperature,
                presentHumidity,
                setHumidity,
                stageNo,
                statusCode,
                runTime,
                programNo,
              } = sensorData?.sensor_data || {};

              return (
                <tr
                  key={index}
                  style={{
                    height: "50px",
                  }}
                >
                  <td className="text-center">{sensorData?.sensor_name}</td>
                  <td className="text-center">
                    {" "}
                    <span
                      style={{
                        backgroundColor: "#FFF9CF",
                        padding: "8px",
                        borderRadius: "8px",
                      }}
                    >
                      {runTime} hrs
                    </span>{" "}
                  </td>
                  <td className="text-center">
                    {parseFloat(presentHumidity)?.toFixed(2)} %
                  </td>
                  <td className="text-center">
                    {parseFloat(presentTemperature)?.toFixed(2)} °C
                  </td>
                  <td className="text-center">
                    {/* <span
                      style={{
                        height: "15px",
                        width: "15px",
                        backgroundColor: statusCode === "on" ? "green" : "red",
                        display: "inline-block",
                        borderRadius: "50%",
                      }}
                    ></span>{" "} */}
                    {statusCode === "on" ? "Running" : "off"}
                  </td>

                  <td className="text-center">{stageNo || "~"}</td>
                  <td className="text-center">{programNo || 1}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .b-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid var(--clr-primary-1);
    border-radius: 8px !important;
    height: 400px;
    overflow-y: auto;
    font-size: 12px;
    thead th {
      position: sticky;
      top: 0;
      background-color: #f1f5f8;
    }

    tbody tr {
      border-bottom: 1px solid #fafafa;
    }

    tbody tr:hover {
      background-color: #f9f9f9;
    }
  }

  table {
    width: 100%;
    table-layout: fixed;
    word-wrap: break-word;
  }

  td,
  th {
    padding: 15px;
    text-align: center;
  }
`;

export default CuringTableView;
