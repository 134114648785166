import { Skeleton } from "@material-ui/lab";
import React, { useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import styled from "styled-components";
import moment from 'moment';

const PasteMixingData = ({ reportData, loading, graphView }) => {
  const tableRef = useRef(null);
  return (
    <section className="section">
      <div className="section-center">
        <Wrapper
          className={
            graphView ||
            loading ||
            reportData?.length === 0 ||
            reportData?.length === undefined
              ? "d-none"
              : ""
          }
        >
          {/* <DownloadTableExcel
            filename="sensordata"
            sheet="sensordata"
            currentTableRef={tableRef.current}
          >
            <button className="btn mb-2 ms-1"> Export </button>
          </DownloadTableExcel> */}
          <div className="b-table">
            <div>
              <table className="table bg-white rounded" ref={tableRef}>
                <thead
                  style={{ borderBottom: "1px solid #FAFAFA" }}
                  className="text-muted"
                >
                  <tr
                    style={{
                      height: "50px",
                      margin: "auto",
                      verticalAlign: "middle",
                      borderBottom: "1px solid #FAFAFA",
                      borderStyle: "none",
                    }}
                  >
                    <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                      <div className="text-center">Date</div>
                    </th>
                    <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                      <div className="text-center">Time</div>
                    </th>
                    <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                      <div className="text-center">Oxide Weight</div>
                    </th>
                    <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                      <div className="text-center">DM Water Weight</div>
                    </th>
                    <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                      <div className="text-center">Acid Weight</div>
                    </th>
                    <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                      <div className="text-center">Temperature</div>
                    </th>
                    <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                      <div className="text-center">Status</div>
                    </th>
                    <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                      <div className="text-center">Batch</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reportData?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td className="text-center">
                          {" "}
                          {moment(item["acquisition-time"]).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>
                          <div className="mt-2 text-center">
                            <span className="rounded-pill p-2">
                              {moment(item["acquisition-time"]).format(
                                "hh:mm a"
                              )}
                            </span>
                          </div>
                        </td>
                        <td className="text-center">
                          {" "}
                          {item["oxideWeight"]?.toFixed(2)} Kg
                        </td>
                        <td className="text-center">
                          {" "}
                          {item["dmWaterWeight"]?.toFixed(2)} Kg
                        </td>
                        <td className="text-center">
                          {" "}
                          {item["acidWeight"]?.toFixed(2)} Kg
                        </td>
                        <td className="text-center">
                          {" "}
                          {item["temperature"]?.toFixed(2)} &deg; C
                        </td>
                        <td className="text-center"> {item["statusCode"]}</td>
                        <td className="text-center"> {item["batchNo"]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Wrapper>
      </div>
    </section>
  );
};
const Wrapper = styled.section`
  .b-table {
    width: 100%;
    border-collapse: collapse;
    border: 3px solid var(--clr-primary-1);
    border-radius: 8px !important;
    height: 500px;
    overflow-y: auto;

    thead th {
      position: sticky;
      top: 0;
      background-color: #f1f5f8;
    }
  }
`;
export default PasteMixingData;
