import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import styled from "styled-components";
import SortView from "../components/SortView";
import ViewSortingButton from "../components/ViewSortingButton";
import { URL, intervalTime } from "../utils/constants";
import usePrevious from "../hooks/usePrevious";
import Motion from "../components/Motion";
import { useUserContext } from "../context/user_context";
import AuthUser from "../components/AuthUser";
import { axios_export_table_instance } from "../utils/axios";
import { Hero } from "../components/Hero";
import { toast } from "react-toastify";
import { TbTemperatureCelsius, TbTemperatureFahrenheit } from "react-icons/tb";
import { DownloadTableExcel } from "react-export-table-to-excel";
import TableData from "../components/TableData";
import { Skeleton } from "@material-ui/lab";

const ReportPageExport = ({ state, handleCheck, cTf }) => {
  const currentDate = moment().format("YYYY-MM-DDTHH:mm");
  const prevDate = moment().subtract(1, "days").format("YYYY-MM-DDTHH:mm");
  const maxDate = moment().endOf("day").format("YYYY-MM-DDTHH:mm");
  const [graphView, setGraphView] = useState(false);
  const { user, http } = AuthUser();
  const { selectedFactoryId } = useUserContext();
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [warehouseList, setWarehouseList] = useState([]);
  const [warehouseId, setWarehouseId] = useState(null);
  const [reportType, setReportType] = useState("");
  const [report, setReport] = useState([]);
  const [tabularReport, setTabularReport] = useState([]);
  const [startDate, setStartDate] = useState(prevDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [deviceId, setDeviceId] = useState(null);
  const [batches, setBatches] = useState([]);
  const [batchNo, setBatchNo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingOnExport, setLoadingOnExport] = useState(false);
  const [loadingOnDownload, setLoadingOnDownload] = useState(false);
  const [loadingWareHouse, setLoadingWareHouse] = useState(false);
  const [thresoldValues, setThresoldValues] = useState({});
  const table_ref = useRef(null);

  const changeView = (e) => {
    const viewType = e.target.id;
    if (viewType === "table_view") {
      setGraphView(false);
    }
    if (viewType === "graph_view") {
      setGraphView(true);
    }
  };

  //fetch process
  const fetchLocations = async () => {
    try {
      const { data } = await http.get(
        `/pms/v1/processes?factory_id=${user.default_factory}`
      );
      setLocations(data);
    } catch (error) {
      console.log(`Error in fetching Process ${error}`);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, [selectedFactoryId]);

  //fetch batches for paste mixing chmaber
  useEffect(async () => {
    if (selectedLocationId === 64) {
      try {
        const res = await http.get(`${URL}/pms/v1/batch_no`);
        if (res.data) {
          setBatches(res.data);
        } else {
          setBatches([]);
        }
      } catch (error) {
        setBatches([]);
        console.log(`Error in fetching batches ${error}`);
      }
    }
  }, [selectedLocationId]);
  const handleOnLocationChange = (e) => {
    setSelectedLocationId(parseInt(e.target.value));
    setSelectedLocation(e.target.options[e.target.selectedIndex].text);
    // setWarehouseId(!warehouseId);
  };

  const handleOnWarehouseChange = (e) => {
    setWarehouseId(parseInt(e.target.value));
    setWarehouseId(e.target.value);
    setReportType(e.target[e.target.selectedIndex].getAttribute("type"));
    setSelectedUnit(e.target[e.target.selectedIndex].getAttribute("name"));
  };

  const handleOnStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleOnEndDateChange = (e) => {
    setEndDate(e.target.value);
    // setEndDate(e.target.value.split('T').join(' '));
    // setEndDate(e.target.value);
  };

  //get unit information (id,identifier)
  const getAllWarehouse = async () => {
    setLoadingWareHouse(true);
    const { data } =
      selectedLocationId &&
      (await http.get(`/pms/v1/sensors?process_id=${selectedLocationId}`));
    setWarehouseList(data);
    // data && data.length > 0 ? setWarehouseList(data) : setWarehouseList([]);
    setLoadingWareHouse(false);

    //set default deviceId
    // data && data.length > 0
    //   ? setDeviceId(data[0].identifier)
    //   : setDeviceId(null);
    // data && data.length > 0
    //   ? setWarehouseId(data[0].warehouseId)
    //   : setWarehouseId(null);
  };

  useEffect(() => {
    getAllWarehouse();
  }, [selectedLocationId]);

  //export selected as csv file
  const exportReport = async () => {
    const formattedStartDate = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD HH:mm:ss");
    const params = {
      responseType: "blob",
    };

    setLoadingOnExport(true);

    await http
      .get(
        `${URL}/pms/v1/report/${reportType}?deviceId=${warehouseId}${
          selectedLocationId === 64 && batchNo ? `&batch_no=${batchNo}` : ""
        }&startDate=${formattedStartDate}&endDate=${formattedEndDate}&export=1&processName=${selectedLocation}&unit=${selectedUnit}`,
        params
      )
      .then((response) => {
        console.log(`Response ${response}`);
        let filename = "";
        let disposition = response.headers["content-disposition"];
        if (disposition && disposition.indexOf("attachment") !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
          }
        }

        // Create Blob with specified MIME type
        const blob = new Blob([response.data]);

        // Create URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        // console.error("Error exporting report:", error.response);
        // toast.dismiss();
        // toast.error(error.response.data[0]);
        if (error.response && error.response.data instanceof Blob) {
          const reader = new FileReader();
          reader.onload = function () {
            const errorText = JSON.parse(reader.result);
            toast.error(errorText[0]);
            console.error("Blob error content:", errorText[0]); // Log the content of the Blob error
          };
          reader.readAsText(error.response.data); // Read error Blob as text
        } else {
          // Log the normal error response data (non-Blob case)
          console.error("Error response data:", error.response?.data);
          toast.dismiss();
          toast.error(error.response?.data?.[0] || "Error exporting report");
        }
      });

    setLoadingOnExport(false);
  };

  return (
    <Motion>
      <Wrapper className="main page-100">
        <div className="section">
          <div className="section-center">
            <div className="row">
              <div className="col-lg-3">
                <div className="box">
                  <div className="row">
                    <label htmlFor="" className="col-6 my-1 col-lg-4 fw-bold">
                      {" "}
                      Process
                    </label>
                    <div className="col-6 col-lg-8">
                      <select
                        disabled={loading}
                        name="location"
                        className="select-input rounded-pill align-middle"
                        id="locations"
                        onChange={(e) => handleOnLocationChange(e)}
                      >
                        <option value="">Select Process</option>
                        {locations.map((location) => (
                          <option
                            key={location.process_id}
                            value={location.process_id}
                          >
                            {location.process_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="box">
                  <div className="row">
                    <label htmlFor="" className="col-6 my-1 col-lg-4 fw-bold">
                      {" "}
                      Unit
                    </label>
                    <div className="col-6 col-lg-8">
                      <select
                        disabled={loading}
                        name="warehouse"
                        className="select-input rounded-pill align-middle"
                        id="locations"
                        onChange={(e) => handleOnWarehouseChange(e)}
                      >
                        <option value="">Select Unit</option>
                        {warehouseList?.map((unit) => (
                          <option
                            key={unit.deviceId}
                            value={unit.deviceId}
                            type={unit.sensor_type}
                            name={unit.name}
                          >
                            {unit.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {selectedLocationId === 64 && (
                <div className="col-12 col-lg-3">
                  <div className="box">
                    <div className="row">
                      <label htmlFor="" className="col-6 my-1 col-lg-4 fw-bold">
                        {" "}
                        Batch No
                      </label>
                      <div className="col-6 col-lg-8">
                        <select
                          // disabled={loading}
                          name="batch"
                          className="select-input rounded-pill"
                          id="locations"
                          onChange={(e) => setBatchNo(e.target.value)}
                        >
                          <option value="">Batch</option>
                          {batches?.map((batch) => (
                            <option key={batch.batchNo} value={batch.batchNo}>
                              {batch.batchNo}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-lg-3 text-start">
                <div className="box">
                  <div className="row">
                    <label htmlFor="" className="col-6 my-1 col-lg-4  fw-bold">
                      From :{" "}
                    </label>
                    <div className="col-6 col-lg-8">
                      <input
                        // disabled={loading}
                        type="datetime-local"
                        value={startDate}
                        onChange={(e) => handleOnStartDateChange(e)}
                        className="select-input  rounded-pill align-middle"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-3">
                <div className="box">
                  <div className="row">
                    <label htmlFor="" className="col-6 my-1 col-lg-4  fw-bold">
                      To :{" "}
                    </label>
                    <div className="col-6 col-lg-8">
                      <input
                        // disabled={loading}
                        type="datetime-local"
                        value={endDate}
                        onChange={(e) => handleOnEndDateChange(e)}
                        className="select-input rounded-pill align-middle"
                        max={maxDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mt-4">
            {selectedLocationId && selectedUnit && startDate && endDate && (
              <button
                className="btn mb-2"
                onClick={exportReport}
                disabled={loadingOnExport}
              >
                {loadingOnExport ? "Exporting..." : "Export"}
              </button>
            )}
          </div>
        </div>
      </Wrapper>
    </Motion>
  );
};

const Wrapper = styled.section`
  .box {
    text-align: start;
    padding: 5px 0;
    margin: 5px 0;
    label {
      white-space: nowrap;
      font-weight: 500;
      font-family: var(--ff-primary);
    }

    #locations,
    input {
      background: transparent;
      padding: 5px;
      width: 150px;
      height: 30px;
      /* border: none; */
      font-size: 14px;
      /* box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2); */
      /* -webkit-appearance: button; */
      /* appearance: button; */
      border: 1px solid var(--clr-primary-1);
      outline: none;
      cursor: pointer;
      text-transform: capitalize;
      font-weight: normal;
    }
  }

  .box select option {
    padding: 30px;
    text-align: center;
    cursor: pointer;
    text-transform: capitalize;
  }
`;
export default ReportPageExport;
