import React, { useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Brush,
} from "recharts";
import styled from "styled-components";
import moment from "moment";
import { AiOutlineClockCircle } from "react-icons/ai";
import Skeleton from "@material-ui/lab/Skeleton";

const CustomTooltip = ({ active, payload, dataKey }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip shadow text-start">
        <h5 className="text-center">{`${payload[0]?.payload[dataKey]}`}</h5>
        <p>
          <AiOutlineClockCircle size={18} />
          <span className="text-center">{` ${moment(
            payload[0].payload["acquisition-time"]
          ).format("hh:mm A")}`}</span>
        </p>
      </div>
    );
  }
  return null;
};

const GraphData = ({
  title,
  data_key,
  report,
  loading,
  state,
}) => {
  const [zoomLevel, setZoomLevel] = useState(1); // Manage zoom level

  // Function to zoom in and out
  const handleZoomOut = () => {
    setZoomLevel((prev) => (prev < 130 ? prev + 5 : prev)); // Increase zoom level, max 100
  };
  
  const handleZoomIn = () => {
    setZoomLevel((prev) => (prev > 1 ? prev - 5 : prev)); // Decrease zoom level, min 1
  };

  // Format X-axis labels
  const formatXAxis = (tickItem) => moment(tickItem).format("HH:mm a");
  const formatXAxis2 = (tickItem) => moment(tickItem).format("MMM DD");

  if (loading) {
    return <Skeleton variant="rect" width="100%" height={300} />;
  }

  // Calculate the minimum chart width based on the length of the data and zoom level
  const chartWidth = report.length > 0 ? Math.max(1000, (report.length * 15) / zoomLevel) : 1000;


  return (
    <Wrapper className="mb-4">
      <h4>{title}</h4>
      <div className="controls">
        <button onClick={handleZoomOut}>Zoom Out</button>
        <button onClick={handleZoomIn}>Zoom In</button>
      </div>
      <div className="chart-wrapper">
        <div
          style={{
            width: chartWidth + "px", // Set dynamic width for the chart container
          }}
        >
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              data={[...report].reverse()}
              margin={{
                right: 20,
              }}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                vertical={false}
                strokeWidth={0.5}
              />
              <XAxis
                dataKey="acquisition-time"
                tickFormatter={formatXAxis}
                xAxisId={0}
              />
              <XAxis
                dataKey="acquisition-time"
                tickFormatter={formatXAxis2}
                xAxisId={1}
                interval={1400} // date
                tickLine={false}
              />
              <YAxis
                dataKey={data_key}
                type="number"
                allowDataOverflow={true}
                domain={[
                  (dataMin) => Math.abs(dataMin) - 5,
                  (dataMax) => Math.abs(dataMax) + 5,
                ]}
              />
              <Tooltip content={<CustomTooltip dataKey={data_key} />} />
              <Line
                type="monotone"
                dataKey={data_key}
                stroke="#4040F2"
                strokeWidth={2}
                dot={false}
              />
              <Brush dataKey="acquisition-time" height={30} stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .controls {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 10px;
  }

  .chart-wrapper {
    overflow-y: hidden;
    overflow-x: auto; /* Enable horizontal scroll */
    width: 100%;
    height: 300px; /* Set a fixed height for the chart wrapper */
  }

  .custom-tooltip {
    background-color: var(--clr-grey-10);
    padding: 5px;
    text-align: center;
    color: var(--clr-primary-1);
  }

  button {
    background-color: #4040f2;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  button:hover {
    background-color: #3030d1;
  }
`;

export default GraphData;
